@import "../../../mixins";

.advantages-card {
  --hidden-padding: #{rem(25)};
  --hidden-content-height: #{rem(64)};

  position: relative;

  padding: rem(50);
  padding-top: rem(45);
  width: 100%;
  max-width: rem(622);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-light);
  border-radius: var(--radius-common);
  overflow: hidden;

  transition: box-shadow var(--animation-default),
    margin-bottom var(--animation-default);

  @include mediaBigDesktop {
    --hidden-padding: #{big(25)};
    --hidden-content-height: #{big(64)};

    padding: big(50);
    padding-top: big(45);
    max-width: big(622);

    border-width: big(1);
  }

  @include mediaDesktop {
    --hidden-content-height: #{rem(54)};

    padding: rem(50);
    max-width: rem(487);
  }

  @include mediaLaptop {
    --hidden-padding: #{rem(20)};
    --hidden-content-height: #{rem(44)};

    padding: rem(30);
    max-width: rem(360);
  }

  @include mediaTablet {
    max-width: rem(334);
  }

  @include mediaMobile {
    --hidden-padding: #{rem(15)};

    padding: rem(20) rem(15);
    max-width: rem(270);
  }

  @include hover {
    margin-bottom: calc((var(--hidden-content-height) + var(--hidden-padding)) * -1);

    & .advantages-card {
      &__hidden {
        padding-top: var(--hidden-padding);
        height: var(--hidden-content-height);
      }
    }
  }

  &__top {
    position: relative;

    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(30);

    @include mediaBigDesktop {
      gap: big(30);
    }

    @include mediaLaptop {
      gap: rem(25);
    }

    @include mediaTablet {
      gap: rem(20);
    }

    @include mediaMobile {
      gap: rem(15);
    }
  }

  &__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: rem(10);

    color: var(--text-dark-primary);

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__icon {
    width: rem(44);
    height: rem(44);

    object-fit: contain;
    object-position: center;

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      width: big(44);
      height: big(44);
    }

    @include mediaDesktop {
      width: rem(30);
      height: rem(30);
    }

    @include mediaLaptop {
      width: rem(26.3);
      height: rem(26.3);
    }

    @include mediaMobile {
      width: rem(22.5);
      height: rem(22.5);
    }
  }

  &__video {
    position: relative;
    z-index: 0;

    width: 100%;
    height: auto;
    aspect-ratio: 522/288;

    border-radius: var(--radius-infinity);
    overflow: hidden;

    user-select: none;
    pointer-events: none;

    @supports not (aspect-ratio: 1/1) {
      height: rem(288);

      @include mediaBigDesktop {
        height: big(288);
      }
    }

    @include mediaDesktop {
      aspect-ratio: 407/228;

      @supports not (aspect-ratio: 1/1) {
        height: rem(228);
      }
    }

    @include mediaLaptop {
      aspect-ratio: 300/174;

      @supports not (aspect-ratio: 1/1) {
        height: rem(174);
      }
    }

    @include mediaTablet {
      aspect-ratio: 274/154;

      @supports not (aspect-ratio: 1/1) {
        height: rem(154);
      }
    }

    @include mediaMobile {
      aspect-ratio: 240/134;

      @supports not (aspect-ratio: 1/1) {
        height: rem(134);
      }
    }

    & video {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: var(--bg-light);
      object-fit: cover;
      object-position: center;

      transition: transform var(--animation-medium);
    }
  }

  &__description {
    padding-top: rem(30);
    width: 100%;
    display: block;

    color: var(--text-dark-secondary);
    font: var(--font-body-M-r);

    @include mediaBigDesktop {
      padding-top: big(30);
    }

    @include mediaDesktop {
      padding-top: rem(25);

      font: var(--font-body-M-m);
    }

    @include mediaLaptop {
      padding-top: rem(20);
    }

    @include mediaMobile {
      padding-top: rem(15);
    }
  }

  &__hidden {
    margin-top: auto;
    
    padding-top: var(--hidden-padding);
    width: 100%;
    height: var(--hidden-content-height);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: content-box;

    overflow: hidden;

    transition: padding-top var(--animation-default),
      height var(--animation-default);

    @media (any-hover: hover) {
      padding-top: 0;
      height: 0;
    }

    & .button {
      @include mediaMobile {
        width: 100%;
      }
    }
  }
}
